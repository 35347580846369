<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <!-- Table Container Card -->

    
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="9"
            class="mobile_tab_max_width_flex all_btn_tab"
          >
            <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                placeholder="Search..."
                @input="filterTable"
                autocomplete="off"
              />

              <!-- <b-button
                variant="danger"
                class="mobile-margin-bottom mr-1"
                @click="openAllValidator()"
                >
                <span class="text-nowrap">Validate Setting</span>
              </b-button> 
              this feature nit required as we set Level 1: Supervisor, Level 2: OE, Level 3: OM, Final Level is Administrator by default for all --> 

              <!-- Component have to add-->
              <DuplicateComponent @filterTable="filterTable()" :site="selected" v-if="checkPermission($route.name, 'Duplicate Item')" />

              
              
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(checkbox)="items">
            
            
            <b-form-checkbox v-model="selected" :value="items.item" class="custom-control-warning my-1" />
        </template>
        <!-- Column: User -->
        <template #cell(site)="items">
           
            <span class="wordBreak">{{ items.item.site_name | capitalize}}</span>
         
        </template>

        <!-- Column: Role -->
        <template #cell(operational_managers)="items">
          
          <span v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(items.item.sites)" v-if=" items.item.operational_managers.length > 0 && getFromAsgSite(items.item.operational_managers).split(',').length > 3">
            {{ getFromAsgSite2(items.item.operational_managers)[0] }}
            <b-badge v-if="getFromAsgSite2(items.item.operational_managers)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(items.item.operational_managers)[1] }} </b-badge>
          </span>
          <span v-if="items.item.operational_managers.length > 0 && getFromAsgSite(items.item.operational_managers).split(',').length <= 3">
            {{ getFromAsgSite2(items.item.operational_managers)[0] }}
          </span>
          <span v-if="items.item.operational_managers.length == 0"> {{ 'Not Assigned' }}</span>
         
        </template>

        <template #cell(supervisors)="items">

          <!-- <span v-b-tooltip.hover.v-primary.top :title="getFromAsgSite(items.item.sites)" v-if="items.item.supervisors.length > 0 && getFromAsgSite(items.item.supervisors).split(',').length > 3"> -->
          <span v-b-tooltip.hover.v-primary.top v-if="items.item.supervisors.length > 0 && items.item.supervisors.length > 3">
            {{ getFromAsgSite2(items.item.supervisors)[0] }}
            <b-badge v-if-else="getFromAsgSite2(items.item.supervisors)[1] != ''" pill variant="primary"> +{{ getFromAsgSite2(items.item.supervisors)[1] }} </b-badge>
          </span>
          <span v-if="items.item.supervisors.length > 0 && getFromAsgSite(items.item.supervisors).split(',').length <= 3">
            {{ getFromAsgSite2(items.item.supervisors)[0] }}
          </span>
          
          <span v-if="items.item.supervisors.length == 0"> {{ 'Not Assigned' }}</span>
           
            
         
        </template>

        <template #cell(item_count)="items">
           
            <span class="wordBreak">{{ items.item.item_count }}</span>
        
        </template>

        
        <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.inventory_status)"
              class="text-capitalize"
            
            >
              {{ items.item.inventory_status }}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <span>{{sitedateTime(items.item.created_at,'DD MMM YYYY','HH:mm')}}</span>
         
        </template>

        <template #cell(actions)="items">

            <b-button
                size="sm"
                variant="warning"
                class="mr-1"
                :to="{ name: 'project-site-items-list', params: { site_id: items.item._id } }"
                v-if="checkPermission($route.name, 'View Items')"
              >
                View Items
              </b-button>

          
            <!-- <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
            >

                <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
                </template>
                

                

                <b-dropdown-item @click="openAssignUser('operation_manager', items.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ items.item.operational_managers.length == 0 ? 'Select OM' : 'Update OM' }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="openAssignUser('supervisor',items.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ items.item.supervisors.length == 0 ? 'Select Supervisor' : 'Update Supervisor'}}</span>
                </b-dropdown-item>

                 <b-dropdown-item @click="openValidator(items.item)">
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">{{ 'Site Validator Settings' }}</span>
                  </b-dropdown-item>
                

                <b-dropdown-item @click="statusChange(items.item)">
                <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
                <span class="align-middle ml-50" v-if="items.item.inventory_status == 'active'">Inactive</span>
                <span class="align-middle ml-50" v-if="items.item.inventory_status == 'inactive'">Active</span>
                </b-dropdown-item>

                
                
              </b-dropdown> -->
              
              <!-- <b-link v-b-tooltip.hover.v-warning title="Site Validator Setting" variant="outline-warning" @click="openValidator(items.item)">
                  <feather-icon icon="EditIcon" class="mediumSize mr-1" />	
              </b-link> 
            this feature nit required as we set Level 1: Supervisor, Level 2: OE, Level 3: OM, Final Level is Administrator by default for all-->

              <b-link v-b-tooltip.hover.v-warning :title= "items.item.inventory_status == 'active' ? 'Mark Inactive' : 'Mark Active'" variant="outline-warning" @click="statusChange(items.item)" v-if="checkPermission($route.name, 'Change Status')">
                  <feather-icon :icon="items.item.inventory_status == 'active' ? 'CheckCircleIcon' : 'CircleIcon'" class="mediumSize mr-1" />	
              </b-link>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
          class="assignCF"
          id="assign_user"
          ref="assign_user"
          cancel-variant="outline-secondary"
          ok-variant="outline-warning"
          ok-title="Submit"
          cancel-title="Close"
          centered
          :title="title"
          @ok="assignUser"
          no-close-on-backdrop
      >

          <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
              <div class="alert-body">
                  {{ error_message }}
              </div>
          </b-alert>

          <b-form>
              <b-row>
                  <b-col cols="12" md="12" class="mb-md-0 mb-2">
                      <b-form-group label="Users" class="required">
                      
                          <v-select
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="allUsers"
                              label="full_name"
                              class="w-100"
                              multiple
                              placeholder="Select User"
                              v-model="selected_user"
                              clearable="true"
                              :close-on-select=false
                            :clear-on-select=false
                          >
                          </v-select>
                      </b-form-group>
                  </b-col>
              </b-row>

          </b-form>
    </b-modal>

    <b-modal
          class="assignCF"
          id="validator"
          ref="validator"
          cancel-variant="outline-secondary"
          ok-variant="outline-warning"
          ok-title="Submit"
          cancel-title="Close"
          centered
          :title="title"
          @ok="assignValidator"
          no-close-on-backdrop
          size="lg"
      >

          <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
              <div class="alert-body">
                  {{ error_message }}
              </div>
          </b-alert>
          
          <b-form>
              <b-row v-if="currentItem != null">
                  <b-col cols="4" md="4" class="mb-md-0 mb-2">
                      <b-form-group label="Validate (Level 1)" class="required">
                        <b-form-select v-model="currentItem.validateLevelOne">
                          <b-form-select-option value=''>Not Applicable</b-form-select-option>
                          <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{ role.name }}</b-form-select-option>
                      
                        </b-form-select>
                          
                      </b-form-group>
                  </b-col>

                  <b-col cols="4" md="4" class="mb-md-0 mb-2">
                      <b-form-group label="Validate (Level 2)" class="required">
                        <b-form-select v-model="currentItem.validateLevelTwo">
                          <b-form-select-option value=''>Not Applicable</b-form-select-option>
                          <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{ role.name }}</b-form-select-option>
                    
                        </b-form-select>
                        
                      </b-form-group>
                  </b-col>

                  <b-col cols="4" md="4" class="mb-md-0 mb-2">
                      <b-form-group label="Validate (Level 3)" class="required">
                        <b-form-select v-model="currentItem.validateLevelThree">
                          <b-form-select-option value=''>Not Applicable</b-form-select-option>
                          <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{ role.name }}</b-form-select-option>
                  
                        </b-form-select>
                      
                      </b-form-group>
                  </b-col>
              </b-row>

          </b-form>
    </b-modal>

    <b-modal
            class="assignCF"
            id="defaultvalidator"
            ref="defaultvalidator"
            cancel-variant="outline-secondary"
            ok-variant="outline-warning"
            ok-title="Submit"
            cancel-title="Close"
            centered
            title="Validate Settings"
            @ok="assignDefaultValidator"
            no-close-on-backdrop
            size="lg"
        >

            <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                <div class="alert-body">
                    {{ error_message }}
                </div>
            </b-alert>
          
            <b-form>
                <b-row v-if="defaultValidator != null">
                    <b-col cols="4" md="4" class="mb-md-0 mb-2">
                        <b-form-group label="Validate (Level 1)" class="required">
                          <b-form-select v-model="defaultValidator.validateLevelOne">
                            <b-form-select-option value=''>Not Applicable</b-form-select-option>
                            <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{ role.name }}</b-form-select-option>
                      
                          </b-form-select>
                          
                        </b-form-group>
                    </b-col>

                    <b-col cols="4" md="4" class="mb-md-0 mb-2">
                        <b-form-group label="Validate (Level 2)" class="required">
                          <b-form-select v-model="defaultValidator.validateLevelTwo">
                            <b-form-select-option value=''>Not Applicable</b-form-select-option>
                            <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{ role.name }}</b-form-select-option>
                    
                          </b-form-select>
                        
                        </b-form-group>
                    </b-col>

                    <b-col cols="4" md="4" class="mb-md-0 mb-2">
                        <b-form-group label="Validate (Level 3)" class="required">
                          <b-form-select v-model="defaultValidator.validateLevelThree">
                            <b-form-select-option value=''>Not Applicable</b-form-select-option>
                            <b-form-select-option :value="role.role" v-for="(role, index) in roles" :key="role._id + index">{{ role.name }}</b-form-select-option>
                  
                          </b-form-select>
                      
                        </b-form-group>
                    </b-col>
                </b-row>

            </b-form>
      </b-modal>

    



  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody, BAlert, VBTooltip, BFormCheckbox,BForm, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'
import DuplicateComponent from "./DuplicateComponent"

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb,
    BFormGroup,
    BFormCheckbox,
    BForm,
    DuplicateComponent,
    BFormSelect, 
    BFormSelectOption
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {

        tableColumns: [
          { key: 'checkbox', label: '', sortable: false, thStyle: { width: '1%' } },
          { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '25%'}},
          // { key: 'operational_managers', label: 'Operational Manager', sortable: false , thStyle:  {width: '18%'}},
          // { key: 'supervisors', label: 'Supervisor', sortable: false, thStyle: { width: '15%' } },
          
          { key: 'item_count', label: 'No. of Items', sortable: false, thStyle: { width: '15%' } },

          { key: 'status', label: 'Status', sortable: false , thStyle:  {width: '15%'}},
          
          { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '21%'}},
        ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
       
        siteData:null,
        sites:[],
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        allUsers:[],
        selected_user:[],
        error_message:null,
        popshowDismissibleAlert:false,
        title:'',
        update_role:'',
        selected_site:'',
        selected:false,
        roles:[],
        currentItem:null,
        defaultValidator:null
    }
  },
  methods : {
    
    breadCrumb(){
      var item = [{
        to: { name: 'client-dashboard' },
        text: 'Dashboard',
      }, {
        to: null,
        text: 'Inventory'
      }, {
        to: null,
        text: 'Settings'
      }, {
        to: null,
        text: 'Project Site Items',
        active: true
      }];;
      return item;
    },
   
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     
                   },
                   api: '/api/project-site-items-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.selected = false;
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    updateSelect(siteIndex, itemIndex){

      return this.$store.dispatch(POST_API, {
        data:{
          site: this.items[siteIndex]._id,
          
          item:this.items[siteIndex].items[itemIndex]._id,
          selected: this.items[siteIndex].items[itemIndex].selected
        },
        api:"/api/assign-pssite-to-items",
      })
      .then(() => {
        if (this.$store.getters.containsErrors) {
          this.error_message = this.$store.getters.getErrors;
          this.errorAlert();
        } else {
          var data = this.$store.getters.getResults.data;

          if (this.items[siteIndex].items[itemIndex].selected == true) {
            this.items[siteIndex].items[itemIndex].selected = false;
          }else{
            this.items[siteIndex].items[itemIndex].selected = true;
          }

        }
      });
    },
    statusChange(item){
      var itemLink = false;

      if(item.items != undefined){
        itemLink = item.items.map(it => it.selected).includes(true);
      }
      
      if (item.inventory_status == 'active' && itemLink == true) {
          var msg = 'Some Items are linked with this Project Inventory Site. Are you sure want to inactivate this record?';
          var message='Project Inventory Site Inactivated Successfully.';
      }else if (item.inventory_status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Project Inventory Site Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Project Inventory Site Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
          })
        .then(result => {
          if (result.value) {
              return this.$store.dispatch(POST_API, {
                data:{
                      id: item._id,
                      status: item.inventory_status
                    },
                api:"/api/change-inventory-site-status",
                  })
                  .then(() => {
                      if (this.$store.getters.containsErrors) {
                          this.error_message = this.$store.getters.getErrors;
                          this.showAlert();
                      } else {
                        
                        Swal.fire({
                          position: 'center',
                          icon: 'success',
                          title: message,
                          showConfirmButton: false,
                          timer: 1500
                        })
                        
                        this.$refs.refUserListTable.refresh();
                      }
                  });
          }
      })
      .catch(err => {
          
      })
    },
    openAssignUser(role,item){

      this.popshowDismissibleAlert = false;
      this.selected_user = [];
      this.selected_site = item._id;
      if(role == 'supervisor'){
        this.title = item.supervisors.length == 0 ? 'Assign Supervisor' : 'Update Supervisor';
        this.selected_user = item.supervisors;
        this.update_role = 'supervisor';
      }else{
        this.title = item.operational_managers.length == 0 ? 'Assign Operational Manager' : 'Update Operational Manager';
        this.selected_user = item.operational_managers;
        this.update_role = 'om';
      }
      
      this.allUsers = [];

      this.allSiteUserByRole(role,item._id);
      this.$refs['assign_user'].show();

    },
    openValidator(item){
      this.popshowDismissibleAlert = false;
      this.currentItem = item;
      this.title = 'Validator Setting - '+item.site_name;
      this.$refs['validator'].show();
    },
    openAllValidator(){
      this.popshowDismissibleAlert = false;
      this.defaultValidator = {
        'validateLevelOne':'',
        'validateLevelTwo': '',
        'validateLevelThree': '',
      }

      this.$refs['defaultvalidator'].show();
    },
    
    allSiteUserByRole(role,site) {
      return this.$store.dispatch(POST_API, {
        data: {
          role: role,
          site:site
        },
        api: '/api/all-site-users-by-role'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {
            this.allUsers = this.$store.getters.getResults.data;

            return this.allUsers;
          }
        });
    },
    assignUser(e) {
      e.preventDefault();
      return this.$store.dispatch(POST_API, {
        data: {

          selected_user: this.selected_user,
          site: this.selected_site,
          role:this.update_role
        },
        api: '/api/assign-inventory-users-to-site'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.popshowDismissibleAlert = true;
          } else {
            this.popshowDismissibleAlert = false;
            var message = this.$store.getters.getResults.message;
            this.successAlert()
              .then((result) => {
                this.$refs['assign_user'].hide();
                this.filterTable();
              });
          }
        });
    },
    assignValidator(e) {
      e.preventDefault();
      return this.$store.dispatch(POST_API, {
        data: {

          item: this.currentItem,
          
        },
        api: '/api/update-inventory-validator'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.popshowDismissibleAlert = true;
          } else {
            this.popshowDismissibleAlert = false;
            var message = this.$store.getters.getResults.message;
            this.successAlert()
              .then((result) => {
                this.$refs['validator'].hide();
                this.filterTable();
              });
          }
        });
    },
    assignDefaultValidator(e) {
      e.preventDefault();
      return this.$store.dispatch(POST_API, {
        data: {

          item: this.defaultValidator,

        },
        api: '/api/update-default-inventory-validator'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.popshowDismissibleAlert = true;
          } else {
            this.popshowDismissibleAlert = false;
            var message = this.$store.getters.getResults.message;
            this.successAlert()
              .then((result) => {
                this.$refs['defaultvalidator'].hide();
                this.filterTable();
              });
          }
        });
    },
    getFromAsgSite(item) {
      if (item.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < item.length; i++) {
          arr.push(item[i].full_name);
        }

        return arr.join(', ');

      }
    },
    getFromAsgSite2(item) {
      if (item.length == 0) {
        return 'N/A';
      } else {
        var arr = [];
        for (var i = 0; i < item.length; i++) {
          
          arr.push(item[i].full_name);
        }

        if (arr.length > 3) {
          return [arr[0] + ', ' + arr[1] + ', ' + arr[2], (arr.length - 3)]
        } else {
          return [arr.join(', '), ''];
        }

      }
    },
    getRoles() {
      return this.$store.dispatch(POST_API, {
        data: {
          role_type:['operation_executive','supervisor','operation_manager','admin']
        },
        api: '/api/get-roles-by-type'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
            this.showDismissibleAlert = true;
            window.scrollTo(0, 0);
          } else {
            this.showDismissibleAlert = false;

            this.roles = this.$store.getters.getResults.data;

            return this.roles;
          }
        });
    },
    
  },
  mounted(){
    this.getRoles();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.border-danger {
  border: 2px solid #ea5455 !important;
  padding: 2px !important;
}
.border-secondary {
  border: 2px solid rgb(221, 221, 221) !important;
  padding: 2px !important;
}
.card-body{
  padding-top: 1rem !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
