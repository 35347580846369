<template>

  <div>
    
    <b-button
    variant="primary"
    class="mobile-margin-bottom"
    :disabled="site == false"
    @click="openAssignSiteModel"
    >
    <span class="text-nowrap">Duplicate Item</span>
    </b-button>

    <!-- Table Container Card -->

    
    <!-- Table Container Card -->
    

    <b-modal
        class="assignCF"
        id="assign_site"
        ref="assign_site"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        :title="'Duplicate Items from Site - ' + site.site_name"
        @ok="assignSite"
        no-close-on-backdrop
        size="lg"
      >

        <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
            <div class="alert-body">
                {{ error_message }}
            </div>
        </b-alert>

        <b-form>
            <b-row>
                <b-col cols="12" md="12" class="mb-md-0 mb-2">

                    <b-form-group label="Duplicate Item to Project Sites" class="required">
                    
                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="assign_sites"
                            label="site_name"
                            class="w-100"
                            multiple
                            placeholder="Select Project Site"
                            v-model="selected_to"
                            :close-on-select=false
                            :clear-on-select=false
                        >
                        
                        </v-select>
                    </b-form-group>

                </b-col>
            </b-row>

            <div>

              <div class="m-2">
                <!-- Table Top -->
                <b-row>

                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="4"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile_tab_max_width_flex mobile_margin_bottom_1rem"
                  >
                    <label>Show</label>
                    <v-select
                      v-model="perPage"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="perPageOptions"
                      :clearable="false"
                      class="per-page-selector d-inline-block mx-50"
                      
                    />
                    <label>entries</label>
                  </b-col>

                  <!-- Search -->
                  <b-col
                    cols="12"
                    md="8"
                    class="mobile_tab_max_width_flex all_btn_tab"
                  >
                    <div class="d-flex align-items-center justify-content-end mobile-view mobile_tab_display_block">
                      <b-form-input
                        v-model="searchQuery"
                        class="d-inline-block mr-1 mobile-margin-bottom mobile-margin-input mobile_margin_bottom_1rem "
                        placeholder="Search..."
                        type="search"
                        autocomplete="off"
                      />

                    </div>
                  </b-col>
                </b-row>

              </div>

              <b-table sticky-header
                responsive
                class="position-relative  dTable"
                id="my-table"
                :items="items"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="tableColumns"
                :filter="searchQuery"
                @filtered="onFiltered"
                show-empty
                empty-text="No matching records found"
              
              >

              <template #cell(checkbox)="items">
            
            
                  <b-form-checkbox v-model="items.item.selected" value="yes" unchecked-value="no" class="custom-control-warning" />
              </template>

              
              </b-table>

              <div class="mx-2 mb-2">
                <b-row>

                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-start"
                  >
                    <span class="text-muted">Showing {{ totalRecords > 0 ? (perPage * (currentPage - 1)) + 1 : 0 }} to 
                      {{ (perPage * (currentPage - 1)) + (totalRecords / (perPage * currentPage) > 1 ?  perPage : (  totalRecords > perPage ? totalRecords % (perPage * (currentPage -1)) : totalRecords)) }} of {{ totalRecords }}</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >

                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRecords"
                      :per-page="perPage"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      aria-controls="my-table"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </div>




        </b-form>
    </b-modal>



  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BFormGroup,
  BBadge, BDropdown, BDropdownItem, BPagination,BBreadcrumb,BCardBody, BAlert, VBTooltip, BFormCheckbox,BForm
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  props:['site'],
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardBody,
    vSelect,
    BBreadcrumb,
    BFormGroup,
    BFormCheckbox,
    BForm
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data() {
    return {

        tableColumns: [
          { key: 'checkbox', label: '', sortable: false, thStyle: { width: '10%' , fontSize: '10px' } },
          { key: 'code', label: 'Internal Item Code', sortable: false, thStyle: { width: '25%' , fontSize: '10px' } },
          { key: 'name', label: 'Item Name', sortable: true, thStyle: { width: '65%' , fontSize: '10px' } },
        ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        error_message:null,
        popshowDismissibleAlert:false,

        assign_sites:[],
        selected_to:[],
    }
  },
  methods : {
    
    openAssignSiteModel() {
      this.items = [];
      this.perPage = 10;
      this.totalRecords = 0;
      this.currentPage = 1;
      this.popshowDismissibleAlert = false;
      this.assign_sites = [];
      this.selected_to = [];
      this.dataList();
      this.allInventorySites();
      this.$refs['assign_site'].show();
    },
    dataList() {
      
      return this.$store.dispatch(POST_API, {
        data: {
          site: this.site._id
        },
        api: '/api/linked-site-items-list'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {

            this.selected = [];
            this.selectAll = false;

            this.items = this.$store.getters.getResults.data;
            this.totalRecords = this.$store.getters.getResults.data.length;
            
            this.from = 0;
            this.to = 50;

            return this.items;
          }
        });
    },
    allInventorySites() {
      return this.$store.dispatch(POST_API, {
        data: {
          role: this.$store.getters.currentUser.role,
        },
        api: '/api/all-inventory-sites'
      })
        .then(() => {
          if (this.$store.getters.containsErrors) {
            this.error_message = this.$store.getters.getErrors;
          } else {
            var data = this.$store.getters.getResults.data;
            //console.log(this.site);
            this.assign_sites = data.filter(item => { return item._id != this.site._id});

            return this.assign_sites;
          }
        });
    },
    assignSite(e){
      
      e.preventDefault();

      var selected_items = this.items.filter(t => { return t.selected == 'yes'}).map(item => { return item._id});

      

      return this.$store.dispatch(POST_API, {
        data: {
          itemsId: selected_items,
          selected_to: this.selected_to,
        },
        api: '/api/copied-items-to-site'
      })
      .then(() => {
        if (this.$store.getters.containsErrors) {
          this.error_message = this.$store.getters.getErrors;
          this.popshowDismissibleAlert = true;
        } else {
          this.popshowDismissibleAlert = false;
          var message = this.$store.getters.getResults.message;
          this.successAlert()
            .then((result) => {
              this.$refs['assign_site'].hide();
              this.$emit('filterTable');
            });
        }
      });

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRecords = filteredItems.length
      this.currentPage = 1
    }
  },
  mounted(){
    console.log('open hua');
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.border-danger {
  border: 2px solid #ea5455 !important;
  padding: 2px !important;
}
.border-secondary {
  border: 2px solid rgb(221, 221, 221) !important;
  padding: 2px !important;
}
.card-body{
  padding-top: 1rem !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
